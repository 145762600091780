import axios from "axios";

export default {
    fetchList({cursor_index, keyword}) {
        return axios.get("/conversations", {
            params: {
                cursor_index,
                keyword
            }
        });
    },
    fetchOne({conversation_id, cursor_index, fetch_user}) {
        return axios.get(`/conversations/${conversation_id}`, {
            params: {
                cursor_index,
                fetch_user
            }
        });
    },
    sendMessage({message, conversation_id, user_id, token}) {
        return axios.post(`/conversations/${conversation_id}/send-message`, {
            message,
            user_id,
            token
        });
    },
    markAsSeenMessage({conversation_id, message_id}) {
        return axios.post(`/conversations/${conversation_id}/mark-as-seen`, {
            message_id
        });
    },
    findConversationByEmployerId({employer_id}) {
        return axios.get(`/conversations/find-conversation`, {
            params: {
                employer_id
            }
        });
    },
    startNewConversation({employer_id, message}) {
        return axios.post(`/conversations/start-new-conversation`, {
            employer_id,
            message
        });
    },
}